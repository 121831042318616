/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import isEmpty from 'lodash/isEmpty'
import Router, { useRouter } from 'next/router'
import qs from 'qs'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { SearchResults, SearchState } from 'react-instantsearch-core'

import { AlgoliaIndex, SortOption } from 'search'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import AlgoliaSearchGridContext, {
  AlgoliaSearchGridContextProps,
} from 'search/components/AlgoliaSearchGridContext'
import DesktopAlgoliaSearchGridLayout, {
  ConsumerSearchDesktopGridLayout,
} from 'search/components/DesktopAlgoliaSearchGridLayout'
import MobileAlgoliaSearchGridLayout, {
  ConsumerSearchMobileGridLayout,
} from 'search/components/MobileAlgoliaSearchGridLayout'
import PageLevelInstantSearch from 'search/components/PageLevelInstantSearch'
import {
  getAllfilters,
  getClientConsumerSearchFilters,
  searchStateToUrl,
} from 'shared/utils/algolia-utils'
import GridHeader from './GridHeader'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import AlgoliaResults, { AlgoliaResultsNoConnect } from 'search/components/AlgoliaResults'

import { toAlgoliaProductHit } from 'layout/navigation/search/utils'
import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import { useLogExperimentWithGroup } from 'experiments/hooks/useLogExperimentWithGroup'
import { getMParticleDeviceId } from 'mParticle/mParticleService'

export interface GridConfig {
  context: AlgoliaSearchGridContextProps
  regex?: RegExp
  indexPath?: string
}
export interface GridProps {
  title: string
  description?: string
  resultsState: SearchResults
  initialSearchState: SearchState
  queryParamSearchState: SearchState
  config: GridConfig
  noResultsState: SearchResults | null
  consumerSearchResultState?: any
  consumerSearchTrendingResults?: any
}

const replaceRouterHref = (searchState, locale) => {
  const urlParams = searchStateToUrl(searchState)
  const href = isEmpty(urlParams)
    ? `${window.location.pathname}`
    : `${window.location.pathname}?${qs.stringify(urlParams)}`

  Router.replace(href, href, { shallow: true, locale })
}

const sortConfig = () =>
  Object.keys(SortOption()).map((option) => ({
    value: AlgoliaIndex[option],
    label: SortOption()[option],
  }))

const Grid: FC<GridProps> = (props) => {
  const {
    initialSearchState,
    queryParamSearchState,
    resultsState,
    title,
    description,
    config,
    noResultsState,
    consumerSearchResultState,
    consumerSearchTrendingResults,
  } = props
  const [algoliaSearchState, setAlgoliaSearchState] = useState(queryParamSearchState)
  const [searchState, setSearchState] = useState(initialSearchState)
  const [modelFilters, setModelFilters] = useState()
  const [currentConsumerSearchResultState, setCurrentConsumerSearchResultState] =
    useState(consumerSearchResultState)
  const isInitialQueryState = useRef<boolean>(true)
  const { context } = config
  const router = useRouter()

  const isConsumerSearchEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_CONSUMER_SEARCH)

  const fetchClientConsumerSearchData = useCallback(
    async ({ currentSearchParams, isAllFilters }) => {
      const url =
        '/api/product-search?' + new URLSearchParams(currentSearchParams as any).toString()
      const { data, error } = await fetch(url, {
        method: 'GET',
      })
        .then(async (res) => {
          return res.json()
        })
        .catch(() => {
          return {
            data: {},
            error: 'error fetching data',
            total: 0,
          }
        })
      if (isAllFilters) {
        // first get all model filters since that doesn't change when user is browsing

        setModelFilters(data?.availableFilters)
      } else {
        // here if router query changes then make call to consumer search api
        const newConsumerSearchResultState = {
          error: error || null,
          data: toAlgoliaProductHit(data?.productsList || []),
          total: data?.totalResults || 0,
          aggregation: {
            filters: data?.availableFilters || {},
          },
        }

        setCurrentConsumerSearchResultState(newConsumerSearchResultState)
      }
    },
    [],
  )

  useEffect(() => {
    if (isConsumerSearchEnabled && initialSearchState) {
      if (isInitialQueryState.current === true) {
        isInitialQueryState.current = false
        // fetch here

        const allFiltersQuery = getAllfilters({
          collection: searchState.collection,
          queryString: router.query?.query,
          brands: (
            searchState.refinementList?.brand || searchState.refinementList?.brand_name
          )?.join(','),
          silhouettes: searchState.refinementList?.silhouette?.join(','),
          activities: searchState.activity?.join(','),
        })

        void fetchClientConsumerSearchData({
          currentSearchParams: allFiltersQuery,
          isAllFilters: true,
        })
      } else {
        const currentSearchParams: any = getClientConsumerSearchFilters(
          searchState || {},
          router.query || {},
        )
        void fetchClientConsumerSearchData({
          currentSearchParams,
        })
      }
    }
  }, [router.query, isConsumerSearchEnabled, searchState, fetchClientConsumerSearchData])

  useEffect(() => {
    setSearchState(initialSearchState)
  }, [initialSearchState])

  useEffect(() => {
    setAlgoliaSearchState(algoliaSearchState)
  }, [algoliaSearchState])

  const handleSearchStateChange = (newSearchState: any) => {
    replaceRouterHref(newSearchState, router.locale)
    // if searchState is empty fallback to initialSearchStatesssss
    // only an issue when toggling between mobile/desktop layouts
    setAlgoliaSearchState(isEmpty(newSearchState) ? algoliaSearchState : newSearchState)
  }

  // experiment
  const consumerSearchExperiment: EXPERIMENT_NAME = 'web_fc_consumer_search'
  const groupName = isConsumerSearchEnabled ? 'consumerSearch' : 'control'

  const deviceIdRef = useRef(getMParticleDeviceId()) // Store device ID

  // CONSUMER SEARCH EXPERIMENT
  // mparticle is set a few seconds maybe minute after the elements on the page have loaded
  // TODO: ensure mparticle loads before the elements on the page are visible
  // most likely will solve the double shift happening when there is a client side experiment
  const { assignGroup } = useLogExperimentWithGroup(consumerSearchExperiment, groupName)

  const [deviceId, setDeviceId] = useState(deviceIdRef.current)

  useEffect(() => {
    if (deviceId) {
      assignGroup()
    }
  }, [deviceId]) // Re-run if deviceId changes

  useEffect(() => {
    let timeoutImparticle: any

    if (!deviceIdRef.current) {
      timeoutImparticle = setInterval(() => {
        const newDeviceId = getMParticleDeviceId()
        if (newDeviceId) {
          deviceIdRef.current = newDeviceId
          setDeviceId(newDeviceId) // Triggers re-render
          clearInterval(timeoutImparticle)
          assignGroup()
        }
      }, 1000)
    } else {
      assignGroup()
    }

    return () => clearInterval(timeoutImparticle) // Cleanup on unmount
  }, [])

  // TODO: here we request the consumersearch results again

  if (!isConsumerSearchEnabled) {
    return (
      <>
        <AlgoliaSearchGridContext.Provider value={context}>
          <PageLevelInstantSearch
            resultsState={resultsState}
            searchState={algoliaSearchState}
            filters={context.filters}
            indexName={context.index || ''}
            onSearchStateChange={handleSearchStateChange}
            hitsPerPage={30}
            searchClient={AlgoliaSearchClient}
            ruleContexts={context?.ruleContexts}
          >
            <AlgoliaResults noResultsState={noResultsState}>
              <GridHeader title={title} description={description} />
              <MobileAlgoliaSearchGridLayout
                sortConfig={sortConfig()}
                defaultRefinement={context.index || AlgoliaIndex.Relevance}
                hiddenFilters={context?.hiddenFilters}
              />
              <DesktopAlgoliaSearchGridLayout
                sortConfig={sortConfig()}
                defaultRefinement={context.index || AlgoliaIndex.Relevance}
                hiddenFilters={context?.hiddenFilters}
              />
            </AlgoliaResults>
          </PageLevelInstantSearch>
        </AlgoliaSearchGridContext.Provider>
      </>
    )
  }

  return (
    <AlgoliaResultsNoConnect
      noResultsState={consumerSearchTrendingResults?.data}
      searchResults={currentConsumerSearchResultState?.data}
      searchState={searchState}
      isAlgolia={false}
    >
      <GridHeader title={title} description={description} />
      {modelFilters && (
        <ConsumerSearchMobileGridLayout
          sortConfig={sortConfig()}
          defaultRefinement={AlgoliaIndex.Relevance}
          nbHits={currentConsumerSearchResultState?.total}
          consumerResults={currentConsumerSearchResultState}
          initialFilters={modelFilters}
          initialSearchState={initialSearchState}
          hiddenFilters={config.context.hiddenFilters}
        />
      )}
      {modelFilters && (
        <ConsumerSearchDesktopGridLayout
          consumerResults={currentConsumerSearchResultState}
          initialFilters={modelFilters}
          initialSearchState={initialSearchState}
          sortConfig={sortConfig()}
          hiddenFilters={config.context.hiddenFilters}
          defaultRefinement={AlgoliaIndex.Relevance}
          nbHits={currentConsumerSearchResultState?.total}
        />
      )}
    </AlgoliaResultsNoConnect>
  )
}
export default Grid
