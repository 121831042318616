/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { uniqBy } from 'lodash/fp'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import countFilters from 'search/utils/count-filters'
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'

import ClearRefinementsButton from './ClearRefinementsButton'
import { SearchQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'search.components.filterHeader'
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`
const FilterLabel = styled.div`
  text-transform: uppercase;
  ${fonts.SUBTITLE_2}
`

const RefinementCount = styled.span`
  color: ${colors.FC2_GREY};
  margin-left: 5px;
`

export const FilterHeader = ({ hiddenFilters, items, refine }: any) => {
  const dedupedItems = uniqBy('id')(items)
  const itemsWithoutHiddenFilters = dedupedItems?.filter(
    (item) => !hiddenFilters?.has(item?.attribute),
  )
  const filterCount = countFilters(itemsWithoutHiddenFilters)
  return (
    <Wrapper>
      <FilterLabel data-qa={SearchQA.DesktopGridAddFilterLabel}>
        {t(`${TP}.filter`, 'Filter')}{' '}
        {filterCount > 0 && <RefinementCount>{filterCount}</RefinementCount>}
      </FilterLabel>
      {filterCount > 0 && <ClearRefinementsButton items={items} refine={refine} />}
    </Wrapper>
  )
}

export default connectCurrentRefinements(FilterHeader)
