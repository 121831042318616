import { FC } from 'react'
import { connectStats } from 'react-instantsearch-dom'
import { colors, fonts, media } from 'shared/lib'
import styled from 'styled-components'
import { SearchQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'search.components.resultsCounter'

const ResultsLabel = styled.div`
  text-transform: uppercase;
  ${fonts.SUBTITLE_2}
  padding: 10px 0 20px;
  ${media.large`
    padding: 0 0 30px;
  `}
`
const ResultsOfCounter = styled.span`
  color: ${colors.FC2_GREY};
  margin-left: 8px;
`

interface ResultsCounterProps {
  nbHits?: number
}

export const ResultsCounter: FC<ResultsCounterProps> = ({ nbHits }) => {
  return (
    <ResultsLabel>
      {t(`${TP}.results`, 'Results')}
      <ResultsOfCounter data-qa={SearchQA.GridResultCount}>{nbHits}</ResultsOfCounter>
    </ResultsLabel>
  )
}

export default connectStats(ResultsCounter)
