/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'

import { SearchQA } from 'shared/dataAttributes'
import { t } from 'localization'
import ClearRefinementsButton from 'search/components/ClearRefinementsButton'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

const TP = 'search.components.filterHeader'
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`
const FilterLabel = styled.div`
  text-transform: uppercase;
  ${fonts.SUBTITLE_2}
`

const RefinementCount = styled.span`
  color: ${colors.FC2_GREY};
  margin-left: 5px;
`

export const FilterHeader = () => {
  const router = useRouter()
  const filterCount = useCallback(() => {
    // count the number of non empty queries
    const queryCount = Object.keys(router?.query || {}).filter(
      (q) =>
        q &&
        q !== 'query' &&
        router.query[q].length &&
        q !== 'sortBy' &&
        q !== 'page' &&
        q !== 'slug',
    ).length

    const hasTwoPriceFilters = router?.query?.min_price && router?.query?.max_price ? -1 : 0
    const hasTwoYearFilters = router?.query?.year_min && router?.query?.year_max ? -1 : 0

    return queryCount + hasTwoPriceFilters + hasTwoYearFilters
  }, [router.query])

  return (
    <Wrapper>
      <FilterLabel data-qa={SearchQA.DesktopGridAddFilterLabel}>
        {t(`${TP}.filter`, 'Filter')}{' '}
        {filterCount() > 0 && <RefinementCount>{filterCount()}</RefinementCount>}
      </FilterLabel>
      <ClearRefinementsButton
        items={filterCount() > 0 ? 'has items' : []}
        refine={() => {
          let newQuery: any = {}

          if (router.query?.query) {
            newQuery = { query: router.query.query }
          }

          if (router.query?.sortBy) {
            newQuery = { ...newQuery, sortBy: router }
          }

          void router.push(
            `${window.location.pathname}?` + new URLSearchParams(newQuery).toString(),
            undefined,
            {
              shallow: true,
            },
          )
        }}
      />
    </Wrapper>
  )
}

export default FilterHeader
