/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { FC, Fragment, useCallback, useState } from 'react'
import { MobileSearchFiltersLayout, SearchPagination } from 'search'
import { renderUntil } from 'shared/hocs/render'
import { media, styles } from 'shared/lib'
import styled from 'styled-components'

import MobileFilterButton from './MobileFilterButton'
import ConsumerSearchMobileFilterButton from './SearchFilters/NewFilters/MobileFilterButton'

import ResultsCounter, { ResultsCounter as ConsumerSearchResultsCounter } from './ResultsCounter'
import SearchGrid, { ConsumerSearchGrid } from './SearchGrid'
import { useRouter } from 'next/router'
import SearchSort, { SearchSort as ConsumerSearchSearchSort } from './SearchSort'
import { NewMobileFiltersLayout } from './SearchFilters/MobileSearchFiltersLayout'
import { Pagination } from './SearchPagination'

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${styles.mobilePageLayoutPadding};
  ${media.large`display: none;`}
`

const Layout = styled.div`
  padding: ${styles.mobilePageLayoutPadding};
`
interface MobileAlgoliaSearchGridLayoutProps {
  defaultRefinement: string
  hiddenFilters: Set<string>
  sortConfig: any
}

interface MobileGridSearchLayout {
  sortConfig: any
  consumerResults: any
  defaultRefinement: any
  nbHits?: number
  handleQueryChange?: (query: any) => void
}

const MobileGridSearchLayout: FC<MobileGridSearchLayout> = ({
  consumerResults,
  initialFilters,
  hiddenFilters,
  initialSearchState,
  sortConfig,
  defaultRefinement,
}: any) => {
  const router = useRouter()
  const [showFilterTakeOver, setShowFilterTakeOver] = useState(false)
  const handleFilterButtonClick = () => {
    setShowFilterTakeOver(!showFilterTakeOver)
  }
  const [allSilhouettes] = useState(initialFilters?.silhouettesList || [])
  const [initialSearchStateSilhouette] = useState(
    consumerResults?.aggregation?.filters?.silhouettesList || [],
  )

  const sizeCount = useCallback(() => {
    const mensSizes = router.query?.size_men?.split(',')?.length || 0
    const womensSizes = router.query?.size_women?.split(',')?.length || 0
    const youthSizes = router.query?.size_youth?.split(',')?.length || 0

    return mensSizes + womensSizes + youthSizes
  }, [router.query])

  const handleQueryChange = (attr: string, value: string | number) => {
    let newQuery: any = { ...router?.query }

    newQuery = {
      ...newQuery,
      [attr]: value,
    }

    if (attr !== 'page') {
      delete newQuery.page
    }

    if (newQuery[attr] === '') {
      delete newQuery[attr]
    }

    void router.push(
      `${window.location.pathname}?` + new URLSearchParams(newQuery).toString(),
      undefined,
      {
        shallow: true,
      },
    )
  }

  // get the silhouette list from here
  const newSilhouettesList = initialSearchState?.refinementList?.silhouette
    ? initialSearchState.refinementList.silhouette.map((s: string) => ({ name: s, count: 1 }))
    : initialSearchState?.refinementList?.collection_slugs?.[0] ||
      initialSearchState?.refinementList?.brand_name ||
      initialSearchState?.refinementList?.brand
    ? initialSearchStateSilhouette
    : allSilhouettes

  // here make sure the silhouette list is unchanging

  return (
    <Fragment>
      <ActionsWrapper>
        <ConsumerSearchMobileFilterButton onClick={handleFilterButtonClick} />
        <ConsumerSearchSearchSort
          items={sortConfig}
          currentRefinement={defaultRefinement}
          refine={(value) => {
            handleQueryChange?.('sortBy', value)
          }}
        />
      </ActionsWrapper>
      <Layout>
        <ConsumerSearchResultsCounter nbHits={consumerResults?.total} />
        <NewMobileFiltersLayout
          show={showFilterTakeOver}
          toggle={handleFilterButtonClick}
          filters={
            (router.query?.page ? initialFilters : consumerResults?.aggregation?.filters) || {}
          }
          silhouettesList={newSilhouettesList}
          hiddenFilters={hiddenFilters}
          isApparel={router.query?.slug?.includes('apparel')}
        />
        <ConsumerSearchGrid hits={consumerResults?.data || {}} sizeFilterCount={sizeCount()} />
        <Pagination
          currentRefinement={parseInt((router.query?.page as string) || '1')}
          nbPages={
            consumerResults?.data?.length > 0 ? Math.ceil((consumerResults?.total || 0) / 30) : 0
          }
          refine={(value) => {
            handleQueryChange('page', value)
          }}
        />
      </Layout>
    </Fragment>
  )
}

export const ConsumerSearchMobileGridLayout = renderUntil.large(MobileGridSearchLayout)

const MobileAlgoliaSearchGridLayout: FC<MobileAlgoliaSearchGridLayoutProps> = ({
  defaultRefinement,
  hiddenFilters,
  sortConfig,
}) => {
  const [showFilterTakeOver, setShowFilterTakeOver] = useState(false)
  const handleFilterButtonClick = () => {
    setShowFilterTakeOver(!showFilterTakeOver)
  }
  return (
    <Fragment>
      <ActionsWrapper>
        <MobileFilterButton hiddenFilters={hiddenFilters} onClick={handleFilterButtonClick} />
        <SearchSort items={sortConfig} defaultRefinement={defaultRefinement} />
      </ActionsWrapper>
      <Layout>
        <ResultsCounter />
        <MobileSearchFiltersLayout
          hiddenFilters={hiddenFilters}
          show={showFilterTakeOver}
          toggle={handleFilterButtonClick}
        />
        <SearchGrid />
        <SearchPagination />
      </Layout>
    </Fragment>
  )
}

MobileAlgoliaSearchGridLayout.displayName = 'MobileAlgoliaSearchGridLayout'

export default renderUntil.large(MobileAlgoliaSearchGridLayout)
