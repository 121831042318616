/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { FC, useCallback, useState } from 'react'
import { DesktopSearchFiltersLayout, SearchPagination } from 'search'
import SearchSortConnected, { SearchSort } from './SearchSort'
import { renderStart } from 'shared/hocs/render'
import styled from 'styled-components'

import ConnectedFilterHeader from './FilterHeader'
import ResultsCounterConnected, { ResultsCounter } from './ResultsCounter'
import SearchGrid, { ConsumerSearchGrid } from './SearchGrid'
import { pageWidth } from 'shared/lib'
import { NewDesktopFilters } from './SearchFilters/DesktopSearchFiltersLayout'
import FilterHeader from './SearchFilters/NewFilters/FilterHeader'
import { useRouter } from 'next/router'
import { Pagination } from './SearchPagination'

const RightColumn = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  margin-left: 30px;
`
const LeftColumn = styled(RightColumn)`
  margin: 0;
  flex: 1;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  ${pageWidth}
`
const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
interface DesktopAlgoliaSearchGridLayoutProps {
  sortConfig: any
  consumerResults: any
  defaultRefinement: any
  hiddenFilters: Set<string>
  nbHits?: number
  handleQueryChange?: (query: any) => void
}

const DesktopGridSearchLayout: FC<DesktopAlgoliaSearchGridLayoutProps> = ({
  consumerResults,
  initialFilters,
  hiddenFilters,
  initialSearchState,
  sortConfig,
  defaultRefinement,
}: any) => {
  const router = useRouter()
  const [allSilhouettes] = useState(initialFilters?.silhouettesList || [])
  const [initialSearchStateSilhouette] = useState(
    consumerResults?.aggregation?.filters?.silhouettesList || [],
  )

  const sizeCount = useCallback(() => {
    const mensSizes = router.query?.size_men?.split(',')?.length || 0
    const womensSizes = router.query?.size_women?.split(',')?.length || 0
    const youthSizes = router.query?.size_youth?.split(',')?.length || 0

    return mensSizes + womensSizes + youthSizes
  }, [router.query])

  const handleQueryChange = (attr: string, value: string | number) => {
    let newQuery: any = { ...router?.query }

    newQuery = {
      ...newQuery,
      [attr]: value,
    }

    if (attr !== 'page') {
      delete newQuery.page
    }

    if (newQuery[attr] === '') {
      delete newQuery[attr]
    }

    void router.push(
      `${window.location.pathname}?` + new URLSearchParams(newQuery).toString(),
      undefined,
      {
        shallow: true,
      },
    )
  }

  // get the silhouette list from here
  const newSilhouettesList = initialSearchState?.refinementList?.silhouette
    ? initialSearchState.refinementList.silhouette.map((s: string) => ({ name: s, count: 1 }))
    : initialSearchState?.refinementList?.collection_slugs?.[0] ||
      initialSearchState?.refinementList?.brand_name ||
      initialSearchState?.refinementList?.brand
    ? initialSearchStateSilhouette
    : allSilhouettes

  // here make sure the silhouette list is unchanging

  return (
    <Wrapper>
      <LeftColumn>
        <FilterHeader />
        <NewDesktopFilters
          filters={
            (router.query?.page ? initialFilters : consumerResults?.aggregation?.filters) || {}
          }
          silhouettesList={newSilhouettesList}
          hiddenFilters={hiddenFilters}
          isApparel={router.query?.slug?.includes('apparel')}
        />
      </LeftColumn>
      <RightColumn>
        <GridHeader>
          <ResultsCounter nbHits={consumerResults?.total} />
          <SearchSort
            items={sortConfig}
            currentRefinement={defaultRefinement}
            refine={(value) => {
              handleQueryChange?.('sortBy', value)
            }}
          />
        </GridHeader>
        <ConsumerSearchGrid hits={consumerResults?.data || {}} sizeFilterCount={sizeCount()} />
        <Pagination
          currentRefinement={parseInt((router.query?.page as string) || '1')}
          nbPages={
            consumerResults?.data?.length > 0 ? Math.ceil((consumerResults?.total || 0) / 30) : 0
          }
          refine={(value) => {
            handleQueryChange('page', value)
          }}
        />
      </RightColumn>
    </Wrapper>
  )
}

export const ConsumerSearchDesktopGridLayout = renderStart.large(DesktopGridSearchLayout)

const DesktopAlgoliaSearchGridLayout: FC<DesktopAlgoliaSearchGridLayoutProps> = ({
  sortConfig,
  defaultRefinement,
  hiddenFilters,
}) => {
  return (
    <Wrapper>
      <LeftColumn>
        <ConnectedFilterHeader hiddenFilters={hiddenFilters} />
        <DesktopSearchFiltersLayout />
      </LeftColumn>
      <RightColumn>
        <GridHeader>
          <ResultsCounterConnected />
          <SearchSortConnected items={sortConfig} defaultRefinement={defaultRefinement} />
        </GridHeader>
        <SearchGrid />
        <SearchPagination />
      </RightColumn>
    </Wrapper>
  )
}

export default renderStart.large(DesktopAlgoliaSearchGridLayout)
