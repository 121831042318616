/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { FC, ReactChildren } from 'react'
import { SearchResults, StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-dom'
import { colors, media } from 'shared/lib'
import styled from 'styled-components'
import { isEmpty } from 'lodash/fp'
import GridHeader from 'shared/components/GridHeader'
import { clickableImagesContent } from 'homepage/clickableImagesContent'
import ClickableImagesRow from 'shared/components/ClickableImagesRow'
import TrendingProducts from 'search/components/TrendingProducts'

import { t } from 'localization'

const TP = 'search.components.AlgoliaResults'

const GridContainer = styled.div`
  background-color: ${colors.FC2_OFF_WHITE};
  ${media.large`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

/**
 * Component to conditionally display children based on algolia search results
 *
 * Need to set display none instead of conditionally render children because of
 * issues with algolia react components. See:
 * https://github.com/algolia/react-instantsearch/issues/137#issuecomment-349385276
 * for more info
 *
 * Usage:
 * <InstantSearch
 *     resultsState={resultsState}
 * >
 *   <AlgoliaResults>
 *     <Hits />
 *     <Filters />
 *   </AlgoliaResults>
 * </InstantSearch>
 */
interface IAlgoliaResults extends StateResultsProvided {
  noResultsState: SearchResults | null
  children: ReactChildren
}

export const AlgoliaResultsNoConnect: FC<{
  searchResults: SearchResults | any
  searchState: any
  noResultsState: SearchResults | null
  children: ReactChildren
  isAlgolia?: boolean
}> = ({ searchResults, searchState, noResultsState, children, isAlgolia }) => {
  const hasNoResults = isAlgolia ? searchResults?.nbHits === 0 : (searchResults || [])?.length === 0

  const onlyQueryTermActive =
    !!searchState?.query && isEmpty(searchState?.refinementList) && isEmpty(searchState?.range)

  const noResultsTitle =
    searchState && !isEmpty(searchState.query)
      ? t(`${TP}.noResultsWithQuery`, {
          defaultValue: 'We could not find anything for "{searchStateQuery}"',
          searchStateQuery: searchState.query,
        })
      : t(`${TP}.noResults`, 'No results found')

  const displayNoResultsGrid = hasNoResults && onlyQueryTermActive

  return (
    <>
      {displayNoResultsGrid && (
        <>
          <GridHeader
            as="h2"
            title={noResultsTitle}
            description={t(
              `${TP}.noResultTitle`,
              'Check the spelling, search with a new term, or use links above to find what you are looking for.',
            )}
            textAlign="center"
          />
          {noResultsState && (
            <TrendingProducts
              resultsState={isAlgolia === false ? null : noResultsState}
              isAlgolia={isAlgolia}
              hits={isAlgolia === false ? noResultsState : []}
            />
          )}
          <ClickableImagesRow imagesList={clickableImagesContent()} />
        </>
      )}
      {!displayNoResultsGrid && <GridContainer>{children}</GridContainer>}
    </>
  )
}

const AlgoliaResults: FC<IAlgoliaResults> = ({
  searchResults,
  searchState,
  noResultsState,
  children,
}) => {
  return (
    <AlgoliaResultsNoConnect
      searchResults={searchResults}
      searchState={searchState}
      noResultsState={noResultsState}
      isAlgolia={true}
    >
      {children}
    </AlgoliaResultsNoConnect>
  )
}

export default connectStateResults<IAlgoliaResults>(AlgoliaResults)
