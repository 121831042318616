/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { FC } from 'react'
import { SearchResults } from 'react-instantsearch-core'
import { AlgoliaIndex } from 'search'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import PageLevelInstantSearch from 'search/components/PageLevelInstantSearch'
import ProductHits, { Hits } from 'search/components/ProductHits'
import Button from 'shared/components/Button'
import { SearchQA } from 'shared/dataAttributes'
import { fonts, media, pageWidth, styles } from 'shared/lib'
import styled from 'styled-components'
import { t } from 'localization'

const TP = 'search.components.TrendingProducts'

const Container = styled.div`
  ${pageWidth}
  margin: 0 ${styles.mobilePageLayoutPadding};
  padding-top: 60px;
  padding-bottom: 60px;
  ${media.large`
    margin: auto;
  `}
`

const Title = styled.h2`
  ${fonts.HEADER_1}
  margin-top: 0;
`

const SeeAllButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 10px;
  ${media.large`
    margin-top: 30px;
  `}
`

interface TrendingProductsProps {
  resultsState?: SearchResults
  isAlgolia?: boolean
  hits?: any[]
}

const TrendingProducts: FC<TrendingProductsProps> = ({ resultsState, isAlgolia, hits }) => {
  if (isAlgolia === false) {
    return (
      <Container>
        <Title data-qa={SearchQA.GridTitleNameTrending}>{t(`${TP}.trending`, 'Trending')}</Title>

        <Hits hits={hits || []} isAlgolia={isAlgolia} />

        <SeeAllButtonContainer>
          <Button buttonType="primary1" data-qa={SearchQA.GridShopAllTrendingLink} href="/trending">
            {t(`${TP}.shopAllTrending`, 'Shop All Trending')}
          </Button>
        </SeeAllButtonContainer>
      </Container>
    )
  }
  return (
    <Container>
      <Title data-qa={SearchQA.GridTitleNameTrending}>{t(`${TP}.trending`, 'Trending')}</Title>
      <PageLevelInstantSearch
        resultsState={resultsState}
        indexName={AlgoliaIndex.Trending}
        searchClient={AlgoliaSearchClient}
      >
        <ProductHits />
      </PageLevelInstantSearch>
      <SeeAllButtonContainer>
        <Button buttonType="primary1" data-qa={SearchQA.GridShopAllTrendingLink} href="/trending">
          {t(`${TP}.shopAllTrending`, 'Shop All Trending')}
        </Button>
      </SeeAllButtonContainer>
    </Container>
  )
}

export default TrendingProducts
