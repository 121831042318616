/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { t } from 'localization'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { Trans } from 'react-i18next'

import { Button } from 'shared/components/Button'
import { SearchQA } from 'shared/dataAttributes'
import { colors } from 'shared/lib'
import styled from 'styled-components'

const TP = 'search.MobileFilterButton'

const FilterButton = styled(Button)`
  margin-bottom: 15px;
`

const ButtonLabel = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
`

const RefinementCount = styled.span`
  color: ${colors.FC2_GREY};
  margin-right: 5px;
`

export const MobileFilterButton = ({ onClick }: any) => {
  const tv = useTranslation()
  const router = useRouter()
  const filterCount = useCallback(() => {
    // count the number of non empty queries

    const queryCount = Object.keys(router?.query || {}).filter(
      (q) =>
        q &&
        q !== 'query' &&
        router.query[q].length &&
        q !== 'sortBy' &&
        q !== 'page' &&
        q !== 'slug',
    ).length

    const hasTwoPriceFilters = router?.query?.min_price && router?.query?.max_price ? -1 : 0
    const hasTwoYearFilters = router?.query?.year_min && router?.query?.year_max ? -1 : 0

    return queryCount + hasTwoPriceFilters + hasTwoYearFilters
  }, [router.query])

  return (
    <FilterButton
      qaAttr={SearchQA.MobileGridAddFilterButton}
      buttonType="primary1"
      $fill
      onClick={onClick}
    >
      <ButtonLabel>
        {filterCount() === 0 ? (
          t(`${TP}.addFilters`, 'Add Filters')
        ) : (
          <Trans
            i18nKey={`${TP}.filterCount`}
            t={tv.t}
            defaults="{filterCount, plural, =1 {1 Filter} other {# Filters}} Added"
            values={{ filterCount: filterCount() }}
            components={[<RefinementCount key="filter-button-refinement" />]}
          />
        )}
      </ButtonLabel>
    </FilterButton>
  )
}

MobileFilterButton.displayName = 'MobileFilterButton'

export default MobileFilterButton
