import { t } from 'localization'
import { uniqBy } from 'lodash/fp'
import { useTranslation } from 'next-i18next'
import { Trans } from 'react-i18next'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import countFilters from 'search/utils/count-filters'
import { Button } from 'shared/components/Button'
import { SearchQA } from 'shared/dataAttributes'
import { colors } from 'shared/lib'
import styled from 'styled-components'

const TP = 'search.MobileFilterButton'

const FilterButton = styled(Button)`
  margin-bottom: 15px;
`

const ButtonLabel = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
`

const RefinementCount = styled.span`
  color: ${colors.FC2_GREY};
  margin-right: 5px;
`

export const MobileFilterButton = ({ hiddenFilters, items, onClick }) => {
  const tv = useTranslation()
  const dedupedItems = uniqBy('id')(items)
  const itemsWithoutHiddenFilters = dedupedItems?.filter(
    (item) => !hiddenFilters?.has(item?.attribute),
  )
  const filterCount = countFilters(itemsWithoutHiddenFilters)

  return (
    <FilterButton
      qaAttr={SearchQA.MobileGridAddFilterButton}
      buttonType="primary1"
      $fill
      onClick={onClick}
    >
      <ButtonLabel>
        {filterCount === 0 ? (
          t(`${TP}.addFilters`, 'Add Filters')
        ) : (
          <Trans
            i18nKey={`${TP}.filterCount`}
            t={tv.t}
            defaults="{filterCount, plural, =1 {1 Filter} other {# Filters}} Added"
            values={{ filterCount }}
            components={[<RefinementCount />]}
          />
        )}
      </ButtonLabel>
    </FilterButton>
  )
}

MobileFilterButton.displayName = 'MobileFilterButton'

export default connectCurrentRefinements(MobileFilterButton)
